import { request } from '../arogga-api';
import { Status } from '@/services/types/lab-test';

import type { GenericResponse } from '@/services/types/lab-test';
import type { MostBookedTest, RecommendedTest, TestDetails } from '@/services/types/lab-test/tests';

import querystring from 'qs';

export const getFrequentLabItems = (q: {
    page?: number;
    limit?: number;
}): Promise<GenericResponse<{ results: MostBookedTest[]; suggestion: [] }>> => {
    const qs = querystring.stringify(q);
    return request({
        method: 'GET',
        includeGeneral: false,
        endpoint: `lab-search/api/v2/lab-search?${qs}`
    });
};

export const getLabTestsCategories = (q: { page?: number; limit?: number; sectionTag: string; status?: Status; name?: string }) => {
    const qs = querystring.stringify(q);
    return request({
        method: 'GET',
        includeGeneral: false,
        endpoint: `misc/api/v1/category?${qs}`
    });
};

export const getTestsByVendor = ({ page = 1, limit = 10, id = '' }: { page: number; limit: number; id: string }) => {
    const qs = querystring.stringify({ page, limit, id });
    return request({
        method: 'GET',
        includeGeneral: false,
        endpoint: `lab-search/api/v2/lab-search?${qs}`
    });
};

export const getPopularLabItems = (q: { page?: number; limit?: number; status?: Status }): Promise<GenericResponse<MostBookedTest[]>> => {
    const qs = querystring.stringify(q);
    return request({
        method: 'GET',
        includeGeneral: false,
        endpoint: `lab-search/api/v2/lab-search/popular-package?${qs}`
    });
};

export const getLabTests = (q: { page?: number; limit: number; tag?: string; status?: string }) => {
    const qs = querystring.stringify(q);
    return request({
        method: 'GET',
        includeGeneral: false,
        endpoint: `misc/api/v1/lab-items?${qs}`
    });
};

export const getTestDetails = (id: string): Promise<GenericResponse<TestDetails>> => {
    return request({
        method: 'GET',
        includeGeneral: false,
        endpoint: `misc/api/v2/lab-items/${id}`
    });
};

export const getPackageTests = (id: string, q = '') => {
    const qs = querystring.stringify(q);
    return request({
        method: 'GET',
        includeGeneral: false,
        endpoint: `misc/api/v1/lab-items/package/${id}/tests`
    });
};

export const getRiskAssessment = (id: string, q = '') => {
    const qs = querystring.stringify(q);
    return request({
        method: 'GET',
        includeGeneral: false,
        endpoint: `misc/api/v1/lab-items/details/?itemId=${id}`
    });
};

export const getFaq = (id: string, q = '') => {
    const qs = querystring.stringify(q);
    return request({
        method: 'GET',
        includeGeneral: false,
        endpoint: `misc/api/v1/lab-items/faq/?itemId=${id}`
    });
};

export const getSteps = (q = '') => {
    const qs = querystring.stringify(q);
    return request({
        method: 'GET',
        includeGeneral: false,
        endpoint: `misc/api/v1/lab-steps`
    });
};

export const getRecommendedPackages = (q: any = ''): Promise<GenericResponse<RecommendedTest[]>> => {
    const qs = querystring.stringify(q);
    return request({
        method: 'GET',
        includeGeneral: false,
        endpoint: `misc/api/v2/lab-items/recommendation?${qs}`
    });
};

export const getSearchedLabTests = (q: {
    page?: number;
    limit?: number;
    query?: string;
}): Promise<GenericResponse<{ results: MostBookedTest[]; suggestion: [] }>> => {
    const qs = querystring.stringify(q);
    return request({
        method: 'GET',
        includeGeneral: false,
        endpoint: `lab-search/api/v2/lab-search?${qs}`
    });
};

export const getTrendingTests = (q: { page?: number; limit?: number; query?: string }) => {
    const qs = querystring.stringify(q);
    return request({
        method: 'GET',
        includeGeneral: false,
        endpoint: `lab-search/api/v1/search/trending?${qs}`
    });
};

export const getSortedTests = (q: any) => {
    const qs = querystring.stringify(q);
    return request({
        method: 'GET',
        includeGeneral: false,
        endpoint: `lab-search/api/v2/lab-search?${qs}`
    });
};
